import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../apis"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container, Modal, ModalBody, ModalHeader,
  Spinner,
  Table,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const AddNoteDs = props => {
  var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)
  const group = props.location.state.group
  const matiere = props.location.state.matiere
  const group_id = props.location.state.group_id
  const matiere_id = props.location.state.matiere_id
  const type_examen_id = props.location.state.type_examen_id
  const date = props.location.state.date
  const semestre_id = props.location.state.semestre_id
  const id = props.location.state.id
  const type_matiere = props.location.state.type_matiere
  const [modalDelete, setModalDelete] = useState(false)
  useEffect(async () => {
    setModalDelete(false)
    const resE = await API.post("examen/passer/etudiants", {
      group_id: group_id,
      type_examen_id: type_examen_id,
      matiere_id: matiere_id,
      semestre_id: semestre_id,
      date: date,
      annee_id,
      id:id,
      enseignant_id:enseignant_id
    }).then(resE => {
      setEtudiantList(resE.data.Note)
      setLoading(true)
    })
  }, [])

  const changeNote = (event, index) => {
    if (/^[0-9,.]+$/.test(event)) {
      var note = event
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: note }) : el
        )
      )
    } else if (note == "") {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: "" }) : el
        )
      )
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      })
    }
  }

  const changeAbsence = (event, index) => {
    let absence = event.target.checked
    if (absence == 1) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { isPresent: 1, note: "" }) : el
        )
      )
    } else {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { isPresent: 0, note: 0 }) : el
        )
      )
    }
  }

  const save = async () => {
    setLoading(false)
    const res = await API.post("examen/passer/note/add", {
      annee_id,
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      enseignant_id: enseignant_id,
      semestre_id: 1,
      date: date,
      etudiants: etudiantList,
      type_matiere:type_matiere
    }).then(res => {
      props.history.push("Note")
    })
  }
  const toggleDelete = id => {
    setModalDelete(!modalDelete)
  }
  const validate = async () => {
    // setModalDelete(!modalDelete)
    setLoading(false)
    const res = await API.post("examen/passer/note/valider", {
      annee_id,
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      enseignant_id: enseignant_id,
      semestre_id: 1,
      date: date,
      etudiants: etudiantList,
      type_matiere:type_matiere
    }).then(res => {
     props.history.push("/Note")
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Card>
              <CardBody>
                <div className="mt-2">
                  <h5 style={{ color: "red" }}>
                    {" "}
                    NB : Cette action est irréversible
                  </h5>
                  <p>
                    {
                      "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                    }
                  </p>
                </div>
                <div className="d-flex">
                  <CardTitle className="h4 mt-4">Groupe :</CardTitle>
                  <CardTitle
                    style={{ paddingLeft: "5px", color: "#556ee6" }}
                    className="h4 mt-4"
                  >
                    {group}
                  </CardTitle>
                </div>
                <div className="d-flex">
                  <CardTitle className="h4 mb-4">Matière :</CardTitle>
                  <CardTitle
                    style={{ paddingLeft: "5px", color: "#556ee6" }}
                    className="h4 mb-4"
                  >
                    {matiere}
                  </CardTitle>
                </div>
                <div>
                  <Table className="mt-3" striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>CIN</th>
                        <th style={{ textAlign: "center" }}>Etudiant</th>
                        <th style={{ textAlign: "center" }}>Présence</th>
                        <th style={{ textAlign: "center" }}>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {etudiantList.map((el, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}> {el.cin} </td>
                          <td style={{ textAlign: "center" }}> {el.nom} </td>
                          <td
                            className="square-switch"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="checkbox"
                              id={"square-switch" + index}
                              switch="bool"
                              checked={el.isPresent}
                              disabled={el.valider == 1 ? true : false}
                              onChange={e => changeAbsence(e, index)}
                            />
                            <label
                              htmlFor={"square-switch" + index}
                              data-on-label="P"
                              data-off-label="A"
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="text"
                              onChange={e => changeNote(e.target.value, index)}
                              value={el.note != null ? el.note : ""}
                              disabled={el.valider == 1 ? true : false}
                              style={{ textAlign: "center" }}
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="mt-2">
                    <h5 style={{ color: "red" }}>
                      {" "}
                      NB : Cette action est irréversible
                    </h5>
                    <p>
                      {
                        "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                      }
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                      >
                        <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Note")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                      >
                        <button
                            type="button"
                            className="btn btn-primary "
                            onClick={save}
                        >
                          Sauvgarder
                        </button>
                      </div>
                    </Col>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <button
                            type="button"
                            className="btn btn-success "
                            onClick={toggleDelete}
                        >
                          Valider
                        </button>
                      </div>
                    </Col>
                  </div>

                  <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
                    <ModalHeader
                        style={{ width: "100% !important", textAlign: "center !important" }}
                        toggle={toggleDelete}
                        tag="h4"
                    >
                      Validation de notes DS
                    </ModalHeader>
                    <ModalBody>
                      <div
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                          }}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <p>{ " Êtes-vous sûr de vouloir valider cette action ? Si vous la validez, vous ne pourrez plus modifier les notes !"}</p>
                        </div>
                        <div
                            className="hvr-push mt-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginBottom: "15px",
                            }}
                        >
                          <div>
                            <Button
                                color="info"
                                className="btn-rounded "
                                onClick={validate}
                            >
                              <i
                                  style={{ color: "white" }}
                                  className="far fa-thumbs-up"
                              ></i>
                              Oui
                            </Button>
                          </div>
                          <div>
                            <Button
                                onClick={toggleDelete}
                                color="danger"
                                className="btn-rounded "
                            >
                              <i
                                  style={{ color: "white" }}
                                  className="far fa-thumbs-down"
                              ></i>
                              Non
                            </Button>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>


                </div>
              </CardBody>
            </Card>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              Chargement en cours...
            </h4>
          </div>
        )}
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddNoteDs)
AddNoteDs.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
