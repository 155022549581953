import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import APIE from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner } from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//
const DataTableEnseignant = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "nom",
      text: "Nom et prénom",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "grade",
      text: "Grade",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Exploration",
      isDummyField: true,
      text: "Exploration",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/DetailEnseignants?id=" + row.id}>
            <i
              style={{ color: "darkcyan", cursor: "pointer" }}
              className="fas fa-eye"
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "Emploi",
      isDummyField: true,
      text: "Emploi",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/EmploiEnseignants?id=" + row.id + "/" + row.semestre_id}>
            <i
              style={{ color: "darkcyan", cursor: "pointer" }}
              className="fas fa-calendar-alt"
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "id",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <a
          href={
            "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
            row.id +
            "/" +
            row.semestre_id+"/"+annee_id
          }
          target="_blank"
          rel="noreferrer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i
            style={{ color: "#955cd5", cursor: "pointer" }}
            onClick={""}
            className="fas fa-file-download"
          ></i>
        </a>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await APIE.get("user/list_id_enseignant").then(resE => {
      const res = API.post("enseignant/list_by_departement", {
        comptes: resE.data.comptes,
        enseignant_id: enseignant_id,
        semestre_id: props.semestre_value,
        annee_id:annee_id
      }).then(res => {
        setOrders(res.data.enseignants)
        setLoading(true)
      })
    })
  }, [props.semestre_value])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableEnseignant)
DataTableEnseignant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  semestre_value: PropTypes.object,
}
