import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalBody } from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import ISGGB from "../../assets/images/ismsf.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const DetailEmploiGroup = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [groupe, setGroupe] = useState("")
  const [emploiGroupe, setEmploiGroupe] = useState([])
  //
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: emploiGroupe.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPage: emploiGroupe.length,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: emploiGroupe.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "name",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "calendrier",
      isDummyField: true,
      text: props.t("Emploi"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "cornflowerblue", cursor: "pointer" }}
            onClick={() => toggle(row)}
            className="fas fa-calendar-alt"
          ></i>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await APIS.post("enseignant/emploi/group/list", {
      enseignant_id: enseignant_id,
      semestre_id: props.semestre_value,
      annee_id:annee_id
    }).then(res => {
      setEmploiGroupe(res.data.list)
    })
    setLoading(true)
  }, [props.semestre_value])

  const toggle = row => {
    setModal(!modal)
  }

  const rowEvent = {
    onClick: async (e, row) => {
      const resE = await APIS.post("emploi/get_by_group", {
        group_id: row.id,
        semestre_id: props.semestre_value,
      }).then(resE => {
        setQuarts(resE.data.emploi[0].quarts)
        setJours(resE.data.emploi[0].jours)
        setGroupe(resE.data.emploi[0].group)
      })
    },
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={emploiGroupe}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Col sm="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    rowEvents={rowEvent}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    pagination={paginationFactory(pageOptions)}
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {props.t("Chargement en cours...")}
          </h4>
        </div>
      )}
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        style={{
          maxWidth: "90%",
          width: "100%",
        }}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" de la Recherche Scientifique"}

                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Sfax
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"Institut Supérieur de Musique de Sfax"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة ﺻﻔﺎﻗﺲ
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {"اﻟﻤﻌﻬﺪ اﻟﻌﺎﻟﻲ ﻟﻠﻤﻮﺳﻴﻘﻰ ﺑﺼﻔﺎﻗﺲ "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "end",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Année Universitaire: </span>
              <span style={{ fontWeight: "bold" }}>2023/2022</span>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "end",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Semestre:</span>
              <span style={{ fontWeight: "bold" }}>{props.semestre_label}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "13px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <span style={{ textAlign: "center" }}> Groupe: </span>
                <span style={{ fontWeight: "bold" }}>{groupe}</span>
              </div>
            </div>
          </div>
        </div>
        <ModalBody>
          <table className="table table-bordered text-center">
            <thead className="table table-light">
              <tr>
                <th className="col-md-1"></th>
                {quarts.map(el => (
                  <th
                    className="col-md-2"
                    key={el.id_quart}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {el.label_quart}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {jours.map((elem, index) => (
                <tr key={elem.id_jour}>
                  <td style={{ fontWeight: "bold" }}>{elem.label_jour}</td>
                  {elem.seances.map((element, indice) => (
                    <td
                      key={indice}
                      style={{ padding: "0px" }}
                      className="td-emploi"
                    >
                      {element.options.map((elle, ind) => (
                        <div
                          key={ind}
                          style={{
                            padding: "2px 2px 2px 2px",
                            cursor: "pointer",
                            borderBottom:
                              ind == 0 && element.options.length > 1
                                ? "1px solid #eff2f7"
                                : null,
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {elle.label_matiere}
                          </p>
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {elle.label_enseignant}
                          </p>
                          {elle.type == 3 ? (
                            <p
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {elle.group_tp}
                            </p>
                          ) : null}
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {elle.label_salle}
                          </p>
                          {elle.type == 1 ? (
                            <div className="div-icon">
                              <span className="icon-cour">C</span>
                              {element.quan == 1 ? (
                                <div className="icon_quan"></div>
                              ) : null}
                            </div>
                          ) : elle.type == 2 ? (
                            <div className="div-icon">
                              <span className="icon-td">TD</span>
                              {element.quan == 1 ? (
                                <div className="icon_quan"></div>
                              ) : null}
                            </div>
                          ) : elle.type == 3 ? (
                            <div className="div-icon">
                              <span className="icon-tp">TP</span>
                              {element.quan == 1 ? (
                                <div className="icon_quan"></div>
                              ) : null}
                            </div>
                          ) : (
                            <div className="div-icon">
                              <span className="icon-autre">A</span>
                              {element.quan == 1 ? (
                                <div className="icon_quan"></div>
                              ) : null}
                            </div>
                          )}
                        </div>
                      ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DetailEmploiGroup))
DetailEmploiGroup.propTypes = {
  emploiGroupe: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre_value: PropTypes.any,
  semestre_label: PropTypes.any,
}
