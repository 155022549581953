import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import {Row, Col, Spinner, Label, CardTitle} from "reactstrap"
import { withRouter } from "react-router"

//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select";
import FileDownload from "js-file-download";

const DataTableExportFiche = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  var cycle_id = userAuthEnseignant.user.cycle_id
  const [loading, setLoading] = useState(false)
  const [semestre, setSemestre] = useState([])
  const [selectSemestre, setSelectSemestre] = useState("");
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  useEffect(async () => {
    setLoading(true)
    const resNiveau = await APIS.get("niveau/select").then(resNiveau => {
      setNiveau(resNiveau.data.Niveau)
    })
      const resSemestre = await APIS.get("semestre/select_sme").then(resSemestre => {
      setSemestre(resSemestre.data.Semestre)
    })

  }, [])
const upload = async()=> {
  const res = await APIS.post( "fiche/export_excelvoeux_by_matiere_sou", {
       enseignant_id,
       annee_id,
       cycle:cycle_id,
       niveau_id:selectNiveau.value,
       semestre_id:selectSemestre.value
      },
      { responseType: "blob" }
  ).then(res => {
    FileDownload(res.data, "Fiche Voeaux.xlsx")
  })
}
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <div>
            <CardTitle style={{ color: "#556ee6" }} className="h4"> Critéres de Recherches </CardTitle>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Semestre</Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    value={selectSemestre}
                    onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Niveau : </Label>
                <Select
                    options={niveau}
                    isSearchable={true}
                    value={selectNiveau}
                    onChange={e => setSelectNiveau(e)}
                />
              </div>
            </Col>
          </Row>
              <Row>
                <Col lg="12">
                  <div className="text-center mt-4">
                    <button
                        type="button"
                        style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                        className="btn btn-success mb-2 me-2"
                        onClick={upload}
                    ><i
                        style={{ color: "#red", cursor: "pointer", fontSize: "15px", margin:"0px 6px 0px 0px" }}
                        className="fas fa-file-excel"
                    ></i>
                      Exportation Fiche Voeux
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableExportFiche))
DataTableExportFiche.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
