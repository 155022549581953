import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import {Col, Input, Row, Label, Form, Button, Progress} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withTranslation } from "react-i18next"
const AddDemandeAdministrative = props => {
  const [type, setType] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [file, setFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [disbutReg, setDisbutReg] = useState(true)
  var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var user_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [langue, setLangue] = useState([
    { value: 1, label: "Français" },
    { value: 2, label: "Arabe" },
  ])
  const [selectLangue, setSelectLangue] = useState("")
  useEffect(async () => {
    const res = await API.get("document/enseignant/vierge/select").then(res => {
      setType(res.data.DocumentViergeEnseignant)
    })
  }, [])

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }
  const save = async () => {
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if(selectLangue == ""){
      setDisbutReg(true)
      toast.error("⛔  Langue de demande obligatoire !", {
        containerId: "A",
      })
    }else if(selectType == ""){
      setDisbutReg(true)
      toast.error("⛔  Type de demande obligatoire !", {
        containerId: "A",
      })
    }else if(pathFile == ""){
      setDisbutReg(true)
      toast.error("⛔  Fichier obligatoire !", {
        containerId: "A",
      })
    }else{
      setDisbutReg(false)
      const res = await API.post("demande/enseignant/add", {
        type_id: selectType.value,
        file: pathFile,
        enseignant_id:user_id,
        annee_id:annee_id,
        langue_id:selectLangue.value
      }).then(res => {
        if (res.data.status == 200) {
          let data = new FormData()
          data.append("file", file)
          data.append("annee_id", annee_id)
          data.append("enseignant_id", user_id)
          data.append("id", res.data.DemandeEnseignant)
          const options = {
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              let percent = Math.round((loaded * 100) / total)
              if (percent < 100) {
                setUploadPercent(percent)
              }
            },
          }
          API.post("demande/enseignant/upload", data, options).then(res => {
            if (res.data.status == 200) {
              setUploadPercent(100)
              props.setSection(1)
            } else {
              setDisbutReg(true)
              toast.error(
                  "⛔ Problème lors de l'insertion !",
                  {
                    containerId: "A",
                  }
              )
            }
          })
        }
      }).catch(() => {
        setDisbutReg(true)
        toast.error("⛔  Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Langue")}
                </Label>
                <Select
                    options={langue}
                    type="text"
                    onChange={e => setSelectLangue(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Type de demande")}
                </Label>
                <Select
                    options={type}
                    isSearchable={true}
                    onChange={e => setSelectType(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Fichier :</Label>
                <Input
                    onChange={e => addFile(e)}
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept=".pdf"
                />
              </div>
            </Col>
          </Row>
          <Row>
            {uploadPercent > 0 && uploadPercent < 100 ? (
                <Progress
                    striped
                    className="progress-xl"
                    color="success"
                    value={uploadPercent}
                >
                  {uploadPercent}%
                </Progress>
            ) : null}
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " disabled={!disbutReg} onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddDemandeAdministrative)
AddDemandeAdministrative.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
