import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  Input,
  Card,
  CardBody,
  Container,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const AddFicheLibre = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  var departement_id = userAuthEnseignant.user.departement_id
  var specialite_id = userAuthEnseignant.user.specialite_id
  var cycle_id = userAuthEnseignant.user.cycle_id
  const [disbutReg, setDisbutReg] = useState(true)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [selectJour, setSelectJour] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [nbrGroupe, setNbrGroupe] = useState("")
  const [typeInstrument, setTypeInstrument] = useState([]);
  const [selectTypeInstrument, setSelectTypeInstrument] = useState("");
  const [selectDisabled, setSelectDisabled] = useState(true);
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  useEffect(async () => {
    const resType = await APIS.get("type/instrument/select").then(resType => {
      setTypeInstrument(resType.data.TypeInstrument)
    })
    const resE = await APIS.get("enseignant/select_by_departement").then(
      resE => {
        setEnseignant(resE.data.Enseignant)
      }
    )
  }, [])

  const getType = event => {
    setSelectType("")
    setSelectEnseignant("")
    setNbrGroupe("")
    setSelectMatiere(event)
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType(res.data.Matiere)
    })

    APIS.post("matiere/get_type_instrument", {
      name: event.label,
    }).then(res => {
      if(res.data.Matiere == 1){
        setSelectDisabled(false)
      }else{
        setSelectDisabled(true)
      }
    })
  }

  const getMatiere = async event => {
    setSelectType("")
    setSelectEnseignant("")
    setNbrGroupe("")
    setSelectJour("")
    setSelectMatiere("")
    setSelectSemestre(event)
    const res = await APIS.post(
      "matiere/get_matiere_by_departement_by_enseignant",
      { enseignant_id: enseignant_id, semestre_id: event.value ,annee_id,departement_id,specialite_id ,cycle_id}
    ).then(res => {
      setMatiere(res.data.Matiere)
    })
  }


  const save = async () => {
    if(selectSemestre== ""){
      toast.error("⛔ Semestre Obligatoire !", {
        containerId: "A",
      })
    }else if(selectJour == ""){
      toast.error("⛔ Jour Obligatoire !", {
        containerId: "A",
      })
    }else if(selectMatiere ==""){
      toast.error("⛔ Matiere Obligatoire !", {
        containerId: "A",
      })
    }else if (selectType == ""){
      toast.error("⛔ Type Obligatoire !", {
        containerId: "A",
      })
    }else if(selectEnseignant == ""){
      toast.error("⛔ Enseignant Obligatoire !", {
        containerId: "A",
      })
    }else if(nbrGroupe == ""){
      toast.error("⛔ Nb Groupe Obligatoire !", {
        containerId: "A",
      })
    }else{
      // alert(nbrGroupe)
      const res = APIS.post("matiere/get_nb_group", {
        type: selectType.value,
        matiere_id:selectMatiere.value,
        annee_id:annee_id
      }).then(res => {
        if(res.data.NbGroup == nbrGroupe || res.data.NbGroup >= nbrGroupe ){
          if(nbrGroupe != 0 && nbrGroupe > 0){
            setDisbutReg(false)
            if(selectTypeInstrument == ""){
              var type_instrument=null
            }else{
              var type_instrument= selectTypeInstrument.value
            }
            const res =  APIS.post("fiche/add_libre", {
              semestre_id: selectSemestre.value,
              jour_id: selectJour,
              matiere_id: selectMatiere.value,
              enseignant_id: selectEnseignant.value,
              nbr_groupe: nbrGroupe,
              type_matiere: selectType.value,
              annee_id:annee_id,
              departement_id:departement_id,
              type_instrument_id:type_instrument
            }).then(res => {
              props.history.push("/FicheVoeux")
            })
          }else{
            toast.error("⛔ Nb Groupe Invalid !", {
              containerId: "A",
            })
          }
        }
        else{
          toast.error("⛔ Nb Groupe Invalid !", {
            containerId: "A",
          })
        }
      })
    }
  }
  // const save = async () => {
  //   const res = await APIS.post("fiche/add_libre", {
  //     semestre_id: selectSemestre.value,
  //     jour_id: selectJour,
  //     matiere_id: selectMatiere.value,
  //     enseignant_id: selectEnseignant.value,
  //     nbr_groupe: nbrGroupe,
  //     type_matiere: selectType.value,
  //     annee_id,
  //     departement_id
  //   }).then(res => {
  //     props.history.push("/FicheVoeux")
  //   })
  // }
  const getNbGroup = event => {
    setSelectType(event)
    const res = APIS.post("matiere/get_nb_group", {
      type: event.value,
      matiere_id:selectMatiere.value,
      annee_id:annee_id
    }).then(res => {
      setNbrGroupe(res.data.NbGroup)
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {props.t("Semestre")} :
                      </Label>
                      <Select
                        options={semestre}
                        isSearchable={true}
                        onChange={e => getMatiere(e)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {props.t("Choix des jours")} :
                      </Label>
                      <Select
                          value={selectJour}
                        isMulti={true}
                        options={jours}
                        isSearchable={true}
                        onChange={e => setSelectJour(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {props.t("Matière")} :
                      </Label>
                      <Select
                          value={selectMatiere}
                          options={matiere}
                          isSearchable={true}
                          onChange={e => getType(e)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {props.t("Type")} :
                      </Label>
                      <Select
                          value={selectType}
                        options={type}
                        isSearchable={true}
                        onChange={e => getNbGroup(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">{"Type d'instrument :"}</Label>
                      <Select
                          isDisabled={selectDisabled}
                          options={typeInstrument}
                          isSearchable={true}
                          value={selectTypeInstrument}
                          onChange={e => setSelectTypeInstrument(e)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {props.t("Enseignant")} :
                      </Label>
                      <Select
                        options={enseignant}
                        value={selectEnseignant}
                        isSearchable={true}
                        formatGroupLabel={formatGroupLabel}
                        onChange={e => setSelectEnseignant(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {props.t("Nombre de groupes")} :
                      </Label>
                      <Input
                          lg="3"
                          className="form-control"
                          placeholder="Entrer nombre de groupes"
                          type="number"
                          value={nbrGroupe}
                          onChange={e => setNbrGroupe(e.target.value)}
                      />
                      <h6 style={{color:"red"}}>{"Vous êtes limité à un certain nombre et il n'est pas possible de le dépasser."}</h6>
                    </div>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={save}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </Col>
                </div>
              </Form>
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddFicheLibre)
AddFicheLibre.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,

}
