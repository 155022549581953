import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Spinner, Container, Card, CardBody } from "reactstrap"
import API from "../../apis"
import ISGGB from "../../assets/images/ismsf.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const DetailEmploiEnseignant = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
    var annee_id = userAuthEnseignant.user.annee_id
  }
  const [loading, setLoading] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  //
    var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
    var annee = anneeScolaire.label

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const resE = await API.post("emploi/get_by_id", {
      enseignant_id: enseignant_id,
      semestre_id: props.semestre_value,annee_id
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setLoading(true)
    })
  }, [props.semestre_value])

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" "}
                  de la Recherche Scientifique
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Sfax
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"Institut Supérieur de Musique de Sfax"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة ﺻﻔﺎﻗﺲ
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {"اﻟﻤﻌﻬﺪ اﻟﻌﺎﻟﻲ ﻟﻠﻤﻮﺳﻴﻘﻰ ﺑﺼﻔﺎﻗﺲ "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "start",
              }}
            >
              <a
                href={
                  "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
                  enseignant_id + '/' + props.semestre_value+'/'+annee_id
                }
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-primary btn btn-primary">
                  <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                  Emploi
                </button>
              </a>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "end",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Année Universitaire: </span>
              <span style={{ fontWeight: "bold" }}>{annee}</span>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "end",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Semestre:</span>
              <span style={{ fontWeight: "bold" }}>{props.semestre_label}</span>
            </div>
          </div>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th></th>
                    {quarts.map(el => (
                      <th key={el.id_quart} style={{ whiteSpace: "nowrap" }}>
                        {el.label_quart}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jours.map((elem, index) => (
                    <tr key={elem.id_jour}>
                      <td style={{ fontWeight: "bold" }}>{elem.label_jour}</td>
                      {elem.seances.map((element, indice) => (
                        <td
                          key={indice}
                          style={{ padding: "0px" }}
                          className="td-emploi"
                        >
                          {element.options.map((elle, ind) => (
                            <div
                              key={ind}
                              style={{
                                padding: "5px 5px 5px 5px",
                                cursor: "pointer",
                                borderBottom:
                                  ind == 0 && element.options.length > 1
                                    ? "1px solid #eff2f7"
                                    : null,
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_matiere}
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_group}
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_salle}
                              </p>
                              {elle.rattrapage == 1 ? (
                                  <div className="div-icon">
                                              <span className="icon-rattrapage">
                                                R
                                              </span>
                                  </div>
                              ) : null}
                              {element.type == 1 ? (
                                <div className="div-icon">
                                  <span className="icon-cour">C</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 2 ? (
                                <div className="div-icon">
                                  <span className="icon-td">TD</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 3 ? (
                                <div className="div-icon">
                                  <span className="icon-tp">TP</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 4 ? (
                                <div className="div-icon">
                                  <span className="icon-autre">A</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default DetailEmploiEnseignant
DetailEmploiEnseignant.propTypes = {
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  semestre_label: PropTypes.object,
}
