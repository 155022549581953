import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
const DataTableDemandeAdministrative = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var user_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "type",
      text: props.t("Type de demande"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },{
      dataField: "numero_ordre",
      text: props.t("Numéro d'ordre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "langue",
      text: props.t("Langue de demande"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "file",
      text: props.t("Fichier"),
      sort: true,
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <i style={{color: "#955cd5", cursor: "pointer"}}
             className="fas fa-file-download"
             onClick={() => download(row)}
          ></i>
      ),
    },
    {
      dataField: "valider",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          row.valider == 0 ? (
              <div style={{display: "flex", justifyContent: "center"}}><i style={{color: "#FF0000", cursor: "pointer"}}
                                                                          className="fas fa-spinner fa-pulse"></i></div>
          ) :  row.valider == 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "green", cursor: "pointer"}}
                    className="fas fa-check"

                ></i>
              </div>

          ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "red", cursor: "pointer"}}
                    className="fas fa-times"

                ></i>
              </div>
          )
      )
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: props.t("Suppression"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          row.count == 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                    style={{ color: "#ED6464", cursor: "pointer" }}
                    onClick={() => toggle(row.id)}
                    className="fas fa-trash-alt"
                ></i>
              </div>
          ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                    style={{ color: "#a7a3a3", cursor: "pointer" }}
                    className="fas fa-trash-alt"
                    title="Cete demande déja utilisé"
                ></i>
              </div>
          ),
    },
  ]

  const { SearchBar } = Search

  const download = (row) => {
    API.post(
        "demande/enseignant/download",
        {
          id: row.id
        },
        { responseType: "blob" }
    ).then(res => {
      const contentDisposition = res.headers["content-disposition"];
      const match = /filename="(.+)"/.exec(contentDisposition);
      const fileName = match ? match[1] : `${row.file}`;
      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  useEffect(async () => {
    const res = await API.post("demande/enseignant/list/enseignant",{enseignant_id:user_id,annee_id}).then(res => {
      setOrders(res.data.DemandeEnseignant)
    })
    setLoading(true)
  }, [])

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const toggleDelete = async () => {
    const res = await API.post("demande/enseignant/delete", {
      id: id,
    }).then(res => {
      API.post("demande/enseignant/list/enseignant",{enseignant_id:user_id,annee_id}).then(res => {
        setOrders(res.data.DemandeEnseignant)
      })
      setLoading(true)
    })
  }

  useEffect(() => {
    setModal(false)
  }, [orders])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("Chargement")}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggle}
          tag="h4"
        >
          {props.t("Suppression")} {props.t("de Demande Administrative")}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette")}{" "}
                {props.t("demande administrative")} ?
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableDemandeAdministrative))
DataTableDemandeAdministrative.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
