import React, { useEffect, useState } from "react"
import { Button, Input, Modal, ModalBody, Row } from "reactstrap"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const PremiereAnneeSemestreDeux = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var specialite_id = userAuthEnseignant.user.specialite_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [array1, setArray1] = useState([])
  const [array2, setArray2] = useState([])
  const [modalVal, setModalVal] = useState(false)
  const [modalValMa, setModalValMa] = useState(false)
  const [modalDes, setModalDes] = useState(false)
  const [element, setElement] = useState("")
  const [element1, setElement1] = useState("")
  const [index, setIndex] = useState("")
  const [indice, setIndice] = useState("")
  const [ind, setInd] = useState("")
  const [etat, setEtat] = useState("")

  useEffect(async () => {
    const res1 = await APIS.post("fiche/voeux_by_matiere_sou", {
      cycle: 1,
      niveau_semestre: 2,
      enseignant_id: enseignant_id,
      annee_id,specialite_id
    }).then(res1 => {
      setArray1(res1.data.voeux)
    })
    const res2 = await APIS.post("fiche/voeux_by_matiere_sou", {
      cycle: 2,
      niveau_semestre: 2,
      enseignant_id: enseignant_id,
      annee_id,specialite_id
    }).then(res2 => {
      setArray2(res2.data.voeux)
    })
  }, [])

  const addNbrGroupe = (e, index, indice, ind) => {
    var array = array1
    array[index].matiere[indice].cours[ind].nb_group = e
    setArray1(array)
  }
  const addNbrGroupeTD = (e, index, indice, ind) => {
    var array = array1
    array[index].matiere[indice].TD[ind].nb_group = e
    setArray1(array)
  }

  const addNbrGroupeTP = (e, index, indice, ind) => {
    var array = array1
    array[index].matiere[indice].TP[ind].nb_group = e
    setArray1(array)
  }

  const addNbrGroupeAutre = (e, index, indice, ind) => {
    var array = array1
    array[index].matiere[indice].Autre[ind].nb_group = e
    setArray1(array)
  }

  const addNbrGroupeMa = (e, index, indice, ind) => {
    var array = array2
    array[index].matiere[indice].cours[ind].nb_group = e
    setArray2(array)
  }
  const addNbrGroupeMaTD = (e, index, indice, ind) => {
    var array = array2
    array[index].matiere[indice].TD[ind].nb_group = e
    setArray2(array)
  }

  const addNbrGroupeMaTP = (e, index, indice, ind) => {
    var array = array2
    array[index].matiere[indice].TP[ind].nb_group = e
    setArray2(array)
  }

  const addNbrGroupeMaAutre = (e, index, indice, ind) => {
    var array = array2
    array[index].matiere[indice].Autre[ind].nb_group = e
    setArray2(array)
  }

  const toggleVal = (element, index, indice, ind, etat) => {
    setModalVal(!modalVal)
    setElement(element)
    setIndex(index)
    setIndice(indice)
    setInd(ind)
    setEtat(etat)
  }

  const toggleValMa = (element, index, indice, ind, etat) => {
    setModalValMa(!modalValMa)
    setElement(element)
    setIndex(index)
    setIndice(indice)
    setInd(ind)
    setEtat(etat)
  }

  const toggleDes = element => {
    setModalDes(!modalDes)
    setElement1(element)
  }

  const valide = async () => {
    var id = element.id
    if (etat == 1) {
      var nb_groupe = array1[index].matiere[indice].cours[ind].nb_group
      var type=1
    } else if (etat == 2) {
      var nb_groupe = array1[index].matiere[indice].TD[ind].nb_group
      var type=2
    } else if (etat == 3) {
      var nb_groupe = array1[index].matiere[indice].TP[ind].nb_group
      var type=3
    } else if (etat == 4) {
      var nb_groupe = array1[index].matiere[indice].Autre[ind].nb_group
      var type=4
    }
    if (nb_groupe != 0) {
      await APIS.post("matiere/get_nb_group", {
        type:type,
        matiere_id:array1[index].matiere[indice].id_matiere,
        annee_id:annee_id
      }) .then(resGrp => {
        if((resGrp.data.NbGroup == nb_groupe || resGrp.data.NbGroup >= nb_groupe )&& (resGrp.data.NbGroup > 0) && (nb_groupe >0)){
      APIS.post("fiche/valider", {
        id: id,
        etat: 1,
        nb_group: nb_groupe,
      })
          .then(res => {
        setModalVal(false)
        toast.success(" Votre fiche de voeux est validé avec succées ", {
          containerId: "A",
        })
        const res1 = APIS.post("fiche/voeux_by_matiere_sou", {
          cycle: 1,
          niveau_semestre: 2,
          enseignant_id: enseignant_id,
          annee_id
        }).then(res1 => {
          setArray1(res1.data.voeux)
        })
        const res2 = APIS.post("fiche/voeux_by_matiere_sou", {
          cycle: 2,
          niveau_semestre: 2,
          enseignant_id: enseignant_id,
          annee_id
        }).then(res2 => {
          setArray2(res2.data.voeux)
        })
      })
        }else{
          toast.error(" Nombre de groupe invalide !! ", {
            containerId: "A",
          })
        }
      })
    } else {
      toast.error(" Nombre de groupe obligatoire ", {
        containerId: "A",
      })
    }
  }

  const valideMa = async () => {
    var id = element.id
    if (etat == 1) {
      var nb_groupe = array2[index].matiere[indice].cours[ind].nb_group
      var type=1
    } else if (etat == 2) {
      var nb_groupe = array2[index].matiere[indice].TD[ind].nb_group
      var type=2
    } else if (etat == 3) {
      var nb_groupe = array2[index].matiere[indice].TP[ind].nb_group
      var type=3
    } else if (etat == 4) {
      var nb_groupe = array2[index].matiere[indice].Autre[ind].nb_group
      var type=4
    }
    if (nb_groupe != 0) {
      await APIS.post("matiere/get_nb_group", {
        type:type,
        matiere_id:array2[index].matiere[indice].id_matiere,
        annee_id:annee_id
      }) .then(resGrp => {
        if((resGrp.data.NbGroup == nb_groupe || resGrp.data.NbGroup >= nb_groupe )&& (resGrp.data.NbGroup > 0) && (nb_groupe >0)){
      APIS.post("fiche/valider", {
        id: id,
        etat: 1,
        nb_group: nb_groupe,
      })
          .then(res => {
        setModalValMa(false)
        toast.success(" Votre fiche de voeux est validé avec succées ", {
          containerId: "A",
        })
        const res1 = APIS.post("fiche/voeux_by_matiere_sou", {
          cycle: 1,
          niveau_semestre: 2,
          enseignant_id: enseignant_id,
          annee_id
        }).then(res1 => {
          setArray1(res1.data.voeux)
        })
        const res2 = APIS.post("fiche/voeux_by_matiere_sou", {
          cycle: 2,
          niveau_semestre: 2,
          enseignant_id: enseignant_id,
          annee_id
        }).then(res2 => {
          setArray2(res2.data.voeux)
        })
      })
        }else{
          toast.error(" Nombre de groupe invalide !! ", {
            containerId: "A",
          })
        }
      })
    } else {
      toast.error(" Nombre de groupe obligatoire ", {
        containerId: "A",
      })
    }
  }

  const desactive = async () => {
    var id = element1.id
    const res = await APIS.post("fiche/valider", {
      id: id,
      etat: 0,
    }).then(res => {
      setModalDes(false)
      toast.success(" Votre fiche de voeux est désactivé avec succées ", {
        containerId: "A",
      })
      const res1 = APIS.post("fiche/voeux_by_matiere_sou", {
        cycle: 1,
        niveau_semestre: 2,
        enseignant_id: enseignant_id,
        annee_id
      }).then(res1 => {
        setArray1(res1.data.voeux)
      })
      const res2 = APIS.post("fiche/voeux_by_matiere_sou", {
        cycle: 2,
        niveau_semestre: 2,
        enseignant_id: enseignant_id,
        annee_id
      }).then(res2 => {
        setArray2(res2.data.voeux)
      })
    })
  }

  return (
      <React.Fragment>
        <Row>
          <div
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
          >
            <h2 style={{ color: "#f4912d" }}>Cycle :</h2>
            <h2 style={{ paddingLeft: "5px", color: "#f4912d" }}>Licence</h2>
          </div>
          {array1.map((el, index) => (
              <div key={index}>
                <h3
                    style={{
                      paddingTop: "10px",
                      color: "#0074ba",
                      textAlign: "center",
                    }}
                >
                  {el.specalite}
                </h3>
                {el.matiere.map((elem, indice) => (
                    <div key={indice}>
                      {elem.matiere != "" ? (
                          <div>
                            {" "}
                            <div
                                style={{
                                  paddingTop: "10px",
                                  display: "flex",
                                }}
                            >
                              <h4 style={{ color: "#057fc7" }}>Matière :</h4>
                              <h4 style={{ paddingLeft: "5px", color: "#057fc7" }}>
                                {elem.matiere}
                              </h4>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>
                                Cours
                              </h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Type Instrument
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.cours.length != 0
                                    ? elem.cours.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.type_instrument}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupe(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleVal(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              1
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>TD</h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Type Instrument
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.TD.length != 0
                                    ? elem.TD.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.type_instrument}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeTD(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleVal(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              2
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>TP</h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Type Instrument
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.TP.length != 0
                                    ? elem.TP.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.type_instrument}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeTP(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleVal(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              3
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>
                                AUTRES
                              </h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Type Instrument
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.Autre.length != 0
                                    ? elem.Autre.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.type_instrument}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeAutre(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleVal(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              4
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                      ) : null}
                    </div>
                ))}
              </div>
          ))}
        </Row>
        <Row>
          <div
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
          >
            <h2 style={{ color: "#f4912d" }}>Cycle :</h2>
            <h2 style={{ paddingLeft: "5px", color: "#f4912d" }}>Mastère</h2>
          </div>
          {array2.map((el, index) => (
              <div key={index}>
                <h3
                    style={{
                      paddingTop: "10px",
                      color: "#0074ba",
                      textAlign: "center",
                    }}
                >
                  {el.specalite}
                </h3>
                {el.matiere.map((elem, indice) => (
                    <div key={indice}>
                      {elem.matiere != "" ? (
                          <div>
                            {" "}
                            <div
                                style={{
                                  paddingTop: "10px",
                                  display: "flex",
                                }}
                            >
                              <h4 style={{ color: "#057fc7" }}>Matière :</h4>
                              <h4 style={{ paddingLeft: "5px", color: "#057fc7" }}>
                                {elem.matiere}
                              </h4>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>
                                Cours
                              </h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.cours.length != 0
                                    ? elem.cours.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeMa(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleValMa(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              1
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>TD</h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.TD.length != 0
                                    ? elem.TD.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeMaTD(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleValMa(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              2
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>TP</h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.TP.length != 0
                                    ? elem.TP.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.nb_heure}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeMaTP(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleValMa(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              3
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <h5 style={{ textAlign: "center", color: "red" }}>
                                AUTRES
                              </h5>
                              <table className="table table-bordered mt-1">
                                <thead>
                                <tr
                                    style={{
                                      textAlign: "center",
                                      border: "2px solid",
                                      backgroundColor: "#95b3d7",
                                    }}
                                >
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Ordre de préférence
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    CIN
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Enseignant
                                  </th>
                                  <th
                                      className="col-md-3"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Grade
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Statut
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Type Instrument
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre heure
                                  </th>
                                  <th
                                      className="col-md-2"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Nombre de groupes
                                  </th>
                                  <th
                                      className="col-md-1"
                                      style={{
                                        textAlign: "center",
                                        border: "2px solid",
                                      }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {elem.Autre.length != 0
                                    ? elem.Autre.map((element, ind) => (
                                        <tr
                                            style={{
                                              backgroundColor:
                                                  element.priorite == 0 ? "#efe7e7" : null,
                                            }}
                                            key={ind}
                                        >
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.priorite}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.cin}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.enseignant}
                                          </td>
                                          <td
                                              className="col-md-3"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.grade}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-2"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.statut}
                                          </td>
                                          <td
                                              className="col-md-1"
                                              style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                              }}
                                          >
                                            {element.type_instrument}
                                          </td>
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                <Input
                                                    style={{ textAlign: "center" }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={e =>
                                                        addNbrGroupeMaAutre(
                                                            e.target.value,
                                                            index,
                                                            indice,
                                                            ind
                                                        )
                                                    }
                                                />
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-2"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                  }}
                                              >
                                                {element.nb_group}
                                              </td>
                                          )}
                                          {element.etat == 0 ? (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#FF0000",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-spinner fa-pulse"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          toggleValMa(
                                                              element,
                                                              index,
                                                              indice,
                                                              ind,
                                                              4
                                                          )
                                                      }
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          ) : (
                                              <td
                                                  className="col-md-1"
                                                  style={{
                                                    textAlign: "center",
                                                    border: "2px solid",
                                                    verticalAlign: "middle",
                                                  }}
                                              >
                                                <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                >
                                                  <i
                                                      style={{
                                                        color: "#2ca67a",
                                                        cursor: "pointer",
                                                      }}
                                                      className="fas fa-check"
                                                  ></i>
                                                  <i
                                                      style={{
                                                        paddingLeft: "10px",
                                                        color: "#143F6B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => toggleDes(element)}
                                                      className="fas fa-cogs"
                                                  ></i>
                                                </div>
                                              </td>
                                          )}
                                        </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                      ) : null}
                    </div>
                ))}
              </div>
          ))}
        </Row>
        <Modal isOpen={modalVal} toggle={toggleVal} centered={true}>
          <div
              className="modal-header"
              style={{
                width: "100% !important",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              toggle={toggleVal}
          >
            Validation fiche voeux licence
          </div>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button className="btn btn-success" onClick={valide}>
                    <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-check-circle"
                    ></i>
                    Valider
                  </Button>
                </div>
                <div>
                  <Button onClick={toggleVal} className="btn btn-warning">
                    <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-times-circle"
                    ></i>
                    Annuler
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalValMa} toggle={toggleValMa} centered={true}>
          <div
              className="modal-header"
              style={{
                width: "100% !important",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              toggle={toggleValMa}
          >
            Validation fiche voeux mastère
          </div>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button className="btn btn-success" onClick={valideMa}>
                    <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-check-circle"
                    ></i>
                    Valider
                  </Button>
                </div>
                <div>
                  <Button onClick={toggleValMa} className="btn btn-warning">
                    <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-times-circle"
                    ></i>
                    Annuler
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalDes} toggle={toggleDes} centered={true}>
          <div
              className="modal-header"
              style={{
                width: "100% !important",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              toggle={toggleDes}
          >
            Validation fiche voeux
          </div>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button
                      type="button"
                      color="danger"
                      className="btn btn-primary  mb-2 me-2"
                      onClick={desactive}
                  >
                    Desactiver
                  </Button>
                </div>
                <div>
                  <Button onClick={toggleDes} className="btn btn-warning">
                    <i
                        style={{ color: "white", paddingRight: "5px" }}
                        className="fas fa-times-circle"
                    ></i>
                    Annuler
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withTranslation()(PremiereAnneeSemestreDeux)
