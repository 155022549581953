import React, { useState } from "react"
import { Button, Col, Form, Input, Label, Progress, Row } from "reactstrap"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"

const AddTirage = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [file, setFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [nbCopie, setNbCopie] = useState(0);
  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const uploadFile = async () => {
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    let data = new FormData()
    data.append("file", file)
    data.append("enseignant_id", enseignant_id)
    data.append("annee_id", annee_id)
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        if (percent < 100) {
          setUploadPercent(percent)
        }
      },
    }
    API.post("tirage/upload", data, options).then(res => {
      if (res.data.status == 200) {
        const resA = API.post("tirage/add", {
          enseignant_id: enseignant_id,
          nb_copie:nbCopie,
          file: pathFile,
          annee_id
        }).then(resA => {
          setUploadPercent(100)
          props.setSection(1)
        })
      } else {
        toast.error(
          "⛔ Il existe un enseignant qui a une CIN n'existe pas dans la base",
          {
            containerId: "A",
          }
        )
      }
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Demande tirage :</Label>
                <Input
                  onChange={e => addFile(e)}
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept=".pdf, .docs, .PDF , .DOCS"
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Nombre de copie :</Label>
                <Input
                    className="form-control"
                    type="number"
                    id="formFile"
                    value={nbCopie}
                    onChange={e => setNbCopie(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {uploadPercent > 0 && uploadPercent < 100 ? (
              <Progress
                striped
                className="progress-xl"
                color="success"
                value={uploadPercent}
              >
                {uploadPercent}%
              </Progress>
            ) : null}
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button
                type="button"
                className="btn btn-primary "
                onClick={uploadFile}
              >
                Upload
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddTirage
AddTirage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
