import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Spinner } from "reactstrap"
import API from "../../apis"
import ISGGB from "../../assets/images/ismsf.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const SurveillanceSem = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
    var annee_id = userAuthEnseignant.user.annee_id
  }
  const [loading, setLoading] = useState(false)
  const [detailExam, setDetailExam] = useState([])
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.label
  useEffect(async () => {
    const res = API.post("surveillance/list/enseignant", {
      enseignant_id: enseignant_id,
      semestre_id: props.semestre_value,annee_id
    }).then(res => {
      setDetailExam(res.data.Surveillance)
      setLoading(true)
    })
  }, [props.semestre_value])

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" "}
                  de la Recherche Scientifique
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Sfax
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"Institut Supérieur de Musique de Sfax"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة ﺻﻔﺎﻗﺲ
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {"اﻟﻤﻌﻬﺪ اﻟﻌﺎﻟﻲ ﻟﻠﻤﻮﺳﻴﻘﻰ ﺑﺼﻔﺎﻗﺲ "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div
              style={{
                display: "flex",
                fontSize: "20px",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Surveillance</span>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "end",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Année Universitaire: </span>
              <span style={{ fontWeight: "bold" }}>{annee}</span>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
                justifyContent: "end",
              }}
            >
              <span style={{ paddingRight: "4px" }}>Session:</span>
              <span style={{ fontWeight: "bold" }}>{props.semestre_value == 3 ?("Contrôle"):("Principale")}</span>
            </div>
            {props.semestre_value != 3 ?(
                <div style={{
                  display: "flex",
                  fontSize: "13px",
                  justifyContent: "end",
                }}>
                  <span style={{ paddingRight: "4px" }}>Semestre:</span>
                  <span style={{ fontWeight: "bold" }}>{props.semestre_label}</span>
                </div>
            ):(null)}
          </div>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th className="div-center">Journée</th>
                    <th className="div-center">Date</th>
                    <th className="div-center">Horaire</th>
                  </tr>
                </thead>
                {detailExam.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td
                        className="div-center"
                        style={{
                          verticalAlign: "middle",
                        }}
                        rowSpan={el.detail.length + 1}
                      >
                        {el.jour}
                      </td>
                      <td
                        className="div-center"
                        style={{
                          verticalAlign: "middle",
                        }}
                        rowSpan={el.detail.length + 1}
                      >
                        {el.date}
                      </td>
                    </tr>
                    {el.detail.map((elem, indice) => (
                      <tr key={indice}>
                        <td className="div-center">{elem.quart}</td>
                      </tr>
                    ))}
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default SurveillanceSem
SurveillanceSem.propTypes = {
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  semestre_label: PropTypes.object,
}
